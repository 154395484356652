const defaultVal  = { 
    BindLogin:-1, 
    ReturnUrl:"/Auth/Login"
};
function getServiceUrl(name,services){
    let host = "";
	if(services){
		let MicroServices = services.MicroServices;
		MicroServices.forEach(m => {
			if(m.ServiceID == name){
				if(m.ServiceEndpoint.length){
					host =  m.ServiceEndpoint
				}
			}
		})
	}
	return host;
}
// 由后台直接写入index.html模板页面的全局变量
export const Interact = process.env.NODE_ENV=== "development" ?  defaultVal : window.Interact;
//index.html模板页全局引入的变量
export const ExamWeb = window.ExamWeb;

// 根据全局变量，获取各个接口服务对应的地址
export const apiHosts = {
    messageHost:getServiceUrl("exam-message",ExamWeb),
    accountHost:getServiceUrl("exam-account",ExamWeb),
    flowHost:getServiceUrl("exam-flow",ExamWeb),
}

export const DefaultLogoUrl = ExamWeb.WebSite.LoginClient.DefaultLogoUrl; 