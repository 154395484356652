import { request } from './request';
import { apiHosts } from "@/lib/global";
const { messageHost } = apiHosts;
// 接口api
class API{
	//账号密码登录
	SponsorLogin(pd){
		return request.post("/Auth/SponsorLogin",pd)
	}
	// 验证码登录
	CodeLogin(pd){
		return request.post("/Auth/CodeLogin",pd)
	}
	// 重置密码
	ResetPassWordLogin(pd){
		return request.post("/Auth/ResetPassWordLogin",pd)
	}
	//获取二维码生成参数
	WeChatScanCodeLogin(pd){
		return request.get("/Auth/WeChatScanCodeLogin",{params:pd, withCredentials:false})
	}
	// 发送验证码
	SendVerifyShortMessage(pd){
		return request.post(messageHost + "OpenService/v1/ShortMessage/SendVerifyShortMessage",pd)
	}
}
export default new API();