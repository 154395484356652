<template>
	<div>
		<van-button class="gebtn" :disabled="number > 0 || tel==''" type="info" plain size="small" @click="openImgCode">{{number == 0 ? "获取验证码" :number + "s"}}</van-button>
		<van-popup  v-model="show"  class="code-pop-wrap">
			<div class="code-pop">
				<div id="sc" style="margin-left: 10px;margin-top: 10px;margin-right: 10px;margin-bottom: 10px"></div>
			</div>
			<div class="img" @click="show = false"></div>
		</van-popup>
	</div>		
</template>
<script>
	import mixins from "@/mixins/codeBtn";
	export default {
		props:{
			tel:String,
			default:""
		},
		mixins:[mixins],
	}	
</script>
<style lang="less" scoped>
	.gebtn{
		white-space: nowrap;
	}
	.code-wrap{
		display: flex;
		border: 1px solid #ddd;
		.code-input{
			padding: 10px;
			border: 0px;
			line-height: 1.5;
			flex: 2;
		}
		.code{
			flex: 1;
			display: block;
			height: 100%;
		}
	}
	.tip{
		color: red;
		padding-top: 10px;
		text-align: center;
	}
	.code-pop-wrap{
		overflow: visible;
		border-radius: 10px;
		width: 80%;
	}
	.code-pop{
		position: relative;
		padding: 10px;
		z-index: 0;
		background-color: #fff;
		border-radius: 10px;
	}
	.code-pop-wrap .img{
		display: block;
		width: 100%;
		height: 100px;
		background: url(../../assets/mobile/images/code-bg.png) center bottom/100% 100% no-repeat;
		position: absolute;
		top:-90px;
		left: 0px;
		z-index: -1;
	}
</style>
