import Vue from 'vue';
import {
	Button,
	Select,
	Option,
	Form,
	FormItem,
	Input,
	Col,
	Row,
	Loading,
	Dialog,
	Tooltip,
	Message,
	MessageBox,
	Step,
	Steps,
	Upload,
	Checkbox,
	CheckboxGroup,
	Cascader,
	DatePicker,
	Pagination,
	Tabs,
  	TabPane,
} from 'element-ui';
Vue.use(Button)
Vue.use(Select)
Vue.use(Option)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Col)
Vue.use(Row)
Vue.use(Loading)
Vue.use(Dialog)
Vue.use(Tooltip)
Vue.use(Step)
Vue.use(Steps)
Vue.use(Upload)
Vue.use(Checkbox)
Vue.use(CheckboxGroup)
Vue.use(Cascader)
Vue.use(DatePicker)
Vue.use(Pagination)
Vue.use(Tabs)
Vue.use(TabPane)
const msgbox = MessageBox
const { alert, confirm } = msgbox

Vue.prototype.$msgbox = msgbox
Vue.prototype.$alert = alert
Vue.prototype.$confirm = confirm
Vue.prototype.$message = Message