<template>
	<div class="page-login">
		<div class="page-bg">
			<div class="login-title-block">
				<div class="ltitle">欢迎使用诺优考</div>
				<div v-if="pageType == '2'">
					<div class="ltip" v-if="isBindLogin">第一次使用微信登录需绑定手机号码</div>
					<div class="ltip" v-else>首次登录将自动注册</div>
				</div>
			</div>
			<div class="login-block">
				<!-- <div class="logo">
					<img :src="require('@/assets/mobile/images/logo.png')">
				</div> -->
				<!-- <div class="form-title">欢迎使用诺聘</div> -->
				<!-- 账号密码登录 -->
				<div class="login-form" v-if="pageType == '1'">
					<div class="login-field van-hairline--bottom">
						<van-icon color="#999999" class="login-field-icon" class-prefix="my-icon" name="geren"></van-icon>
						<input type="tel" class="login-field-input" maxlength="11" placeholder="请输入手机号" v-model="Form.telephone"/>
					</div>
					<!-- 占位，解决浏览器自动识别密码造成的键盘闪动问题 -->
					<input type="text" style="opacity: 0;width: 0;height: 0;display: block;border:0;padding:0">
					<div class="login-field van-hairline--bottom">
						<van-icon color="#999999" class="login-field-icon"  class-prefix="my-icon" name="mima-01"></van-icon>
						<input type="password" v-model="Form.password" class="login-field-input" placeholder="请输入密码"/>
						<div class="login-a login-field-right" @click="pageType ='4'">忘记密码</div>
					</div>
					<!-- 占位，解决浏览器自动识别密码造成的键盘闪动问题 -->
					<input type="text" style="opacity: 0;width: 0;height: 0;display: block;border:0;padding:0">
					<div class="login-policy">
						<page-agreement></page-agreement>
					</div>
					<van-button class="login-btn" type="info" block round @click="SponsorLogin" :disabled="disabled">登 录</van-button>
					<div class="form-tool form-tool-center">
						<div class="login-a" @click="pageType='2'">短信登录/注册</div>
					</div>
				</div>
				<!-- 账号密码登录end -->
				<!-- 验证码登录 -->
				<div class="login-form" v-if="pageType == '2'">
					<div class="login-field van-hairline--bottom">
						<van-icon color="#999999" class="login-field-icon" class-prefix="my-icon" name="geren"></van-icon>
						<input type='tel' class="login-field-input" maxlength="11" placeholder="请输入手机号" v-model="Form.telephone">
					</div>
					<div class="login-field van-hairline--bottom">
						<van-icon color="#999999" class="login-field-icon"  class-prefix="my-icon" name="mima-01"></van-icon>
						<input type='tel' maxlength="6" class="login-field-input" placeholder="请输入验证码" v-model="Form.code">
						<code-btn  :tel="Form.telephone"></code-btn>
					</div>
					<div class="login-policy">
						<page-agreement></page-agreement>
					</div>
					<van-button class="login-btn" type="info" block round @click="CodeLogin" :disabled="disabled">登录/注册</van-button>
					<div class="form-tool form-tool-center">
						<div @click="pageType = '1'" class="login-a">密码登录</div>
					</div>
				</div>
				<!-- 验证码登录end -->
				<!-- 忘记密码 -->
				<div class="login-form" v-if="pageType == '4'">
					<div class="login-field van-hairline--bottom">
						<van-icon color="#999999" class="login-field-icon" class-prefix="my-icon" name="geren"></van-icon>
						<input type='tel' class="login-field-input" maxlength="11" placeholder="请输入手机号" v-model="Form.telephone">
					</div>
					<div class="login-field van-hairline--bottom">
						<van-icon color="#999999" class="login-field-icon"  class-prefix="my-icon" name="mima-01"></van-icon>
						<input type='tel' maxlength="6" class="login-field-input" placeholder="请输入验证码" v-model="Form.code">
						<code-btn :tel="Form.telephone"></code-btn>
					</div>
					<div class="login-field van-hairline--bottom">
						<van-icon color="#999999" class="login-field-icon"  class-prefix="my-icon" name="mima-01"></van-icon>
						<input type="password" v-model="Form.password" class="login-field-input" placeholder="请输入新密码"/>
					</div>
					<div class="login-policy">
						<page-agreement></page-agreement>
					</div>
					<van-button class="login-btn" type="info" block round @click="resetPassword()" :disabled="disabled">重置密码</van-button>
					<div class="form-tool">
						<div class="left">
							<div @click="pageType = '1'" class="login-a">密码登录</div>
						</div>
						<div class="right">
							<div @click="pageType = '2'" class="login-a">验证码登录</div>
						</div>
					</div>
				</div>
				<!-- 忘记密码 end -->
				<div class="qr" @click="nuopinLogin()">
					<div class="nuopin-logo">
						<img src="../../assets/pc/images/wechat2.png" alt="">
					</div>
					<div class="nuopin-word">诺聘账号登录</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script> 
import hex_md5 from "@/lib/md5.js";
import wx from 'weixin-js-sdk';
import { validTelephone,validCode } from '@/lib/validate'
import pageAgreement from './agreement.vue'
import codeBtn from './codeBtn.vue'
import mixins from "@/mixins/index.js"
export default {
	components:{
		pageAgreement,
		codeBtn
	},
	mixins:[mixins],
	data(){
		return {
			disabled:false,
		}
	},
	methods:{
		validate(){
			let d = this.Form;
			return new Promise((rsl,rej) => {
				switch(this.pageType){
					case "1":{
						if(!validTelephone(d.telephone)){
							this.$toast("请输入正确手机号");
							rej();
						}else if(!d.password.length){
							this.$toast("请输入密码");
							rej();
						}else{
							rsl()
						}
						break;
					}
					case "2":{
						if(!validTelephone(d.telephone)){
							this.$toast("请输入正确手机号");
							rej();
						}else if(!validCode(d.code)){
							this.$toast("请输入正确验证码");
							rej();
						}else{
							rsl()
						}
						break;
					}
					case "4":{
						if(!validTelephone(d.telephone)){
							this.$toast("请输入正确手机号");
							rej();
						}else if(!validCode(d.code)){
							this.$toast("请输入正确验证码");
							rej();
						}else if(!d.password.length){
							this.$toast("请输入密码");
							rej();
						}else{
							rsl()
						}
						break;
					}
				}
			})
		}
	}
}
</script>
<style lang="less" scoped>
	@import "~@/assets/mobile/styles/reset.css";
	@import "~@/assets/mobile/fonts/icomoon/style.css";
	@import "~@/assets/mobile/styles/base.less";
	@import "~@/assets/mobile/styles/component.less";
</style>