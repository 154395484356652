export const policy =  
`<h3>【诺优考】求职者用户服务协议</h3>
一、版权：
此网址的内容和图表受到中国版权法及国际条约的保护。nuoyoukao.com拥有一切权利，未经其同意，不允许复制。以任何目的重新使用nuoyoukao.com网上的内容和图表也受到严格的禁止。在内容和图表不作任何修改，在保留内容未做修改，以及得到nuoyoukao.com的许可的情况下，nuoyoukao.com的网上材料可作为网外信息方面和非商业方面的用途。申请使用 nuoyoukao.com内容的许可是按逐个批准的原则进行的。nuoyoukao.com欢迎提出申请。请把请求直接提交给hbnp2021@126.com。不要复制或采用nuoyoukao.com所创造的用以制成网页的HTML。HTML也属于nuoyoukao.com的版 权。nuoyoukao.com网址上的“观看”和“感觉”也属nuoyoukao.com的商标，其中包括颜色、按钮的形状、设计和所有其他的图表。
二、商标：
nuoyoukao.com的商标，包括 nuoyoukao.com和nuoyoukao.com其他网站的商标属于nuoyoukao.com 所有。nuoyoukao.com上的nuoyoukao.com合作者的商标属于合作者所有。
三、责任：
nuoyoukao.com并无随时监视此网址，但保留权利进行随时的监视。对于别人输入的，不是我公司公布的材料，nuoyoukao.com一概不负任何责任。使用者承认并同意，他对存入的个人信息及材料的格式、内容、准确性负责。
四、网站使用：
1、明确的禁止使用：nuoyoukao.com只能用于合法目的，即个人寻找职业和雇主寻找雇员。nuoyoukao.com明确禁止任何其他用途，并要求所有使用者同意不用于下列任何一种用途： 
（1）不要在简历中心公布不完整、虚假或不准确的简历资料，或不是你自己的准确简历(而为他人寻找全职或兼职工作)。不要公布不是简历的资料，如意见、通知、商业广告或其他内容。
（2）不要回应职位数据库中任何一种就业机会，除非你有意申请该职位。禁止我们的竞争同行用此方法寻求与雇主联络业务。
（3）不要删除或修改其他个人或公司公布的资料。
（4）不要打印、复制或使用有关雇员的任何个人资料或 有关雇主的商业信息。未经同意，给公布信息的个人或公司发电子邮件、打电话、寄信或进行其他接触是被禁止的。
2、网址安全法规：
禁止使用者破坏或企图破坏nuoyoukao.com的安全法规，其中包括：
（1）接触未经许可的数据或进入未经许可的服务器或帐户；
（2）没有得到许可，企图探查、扫描或测试系统或网络的弱点，或者破坏安全措施；
（3）企图干涉对用户及网络的服务，包括， 并不限于，通过超载、“邮件炸弹”或“摧毁”等手段；
（4）发送促销，产品广告及服务的email；
（5）伪造TCP/IP数据包名称或部分名称。破坏系统或网络可能导致犯罪。nuoyoukao.com将调查此类破坏行为的发生，并可能干预，和执法当局合作，起诉犯此类破坏行为 的使用者。 
3、总则：在下列三种情况下：
（1）违反任何应用法规；
（2）侵犯别人的版权、商业机密、或其 他知识产权，或违反了别人的隐私权、出版权或其他个人权利； 
（3）属于诽谤的、淫秽的、威胁的、辱骂的、憎恨的材料，使用者不可利用此网址传送、分发、储存或毁掉材料。
五、暂停使用，中止使用：
任何一位使用者经nuoyoukao.com确定已违反了网站使用规则某一项规定，将收到一份书面警告。如果该使用者同意以书面形式表示自己再也不会有违犯行为，nuoyoukao.com可以确定是否给予暂停使用的处理。然而，如果我们认为必要时，也可不提出警告而马上暂停或终止对该使用者的服务。如果我们确定某一使用者再次违犯了网络使用规则的任何一项规定，无需再发通知，该使用者立即受到暂停使用或终止使用的处理。
六、用户隐私：
除了本网址在信息保密中提出的条款外，使用者理解并同意 ，nuoyoukao.com在不公开姓名的情况下，可以向第三方提供综合性的资料。
除非法律方面的需要，在没有本人事先同意的情况下，nuoyoukao.com不会向 第三方公开你的姓名、地址、电子邮件和电话。
注册使用本网站，表明本人同意将个人信息公开给要招聘人员的企业，如因个人原因暂不想公开自己的信息，可以自行到个人管理后台，将简历设置为不公开的状态。
七、法律声明：
你使用本网址承担风险。本网址的材料是按“正如… …的情况”所提供的，对材料不作明显的或暗含的保证。依照使用法规在最大限度允许的情况下，nuoyoukao.com及其所属网络对销售性的和适合于某一特定目的的一切保证不予承认。 nuoyoukao.com不能保证，材料的特殊目的不受阻挠不出错误，也不能保证错误会得到纠正，或本网址或制成本网址的，不会有病毒或其他有害成分。在有关材料的使用或使用结果方面对材料的正确性、准确性、可靠性或其他方面，nuoyoukao.com不作出保证或任何说明。你（而不是nuoyoukao.com）承担一切必要的服务、修理或改正费用。在应用法规不允许暗含保证可免除承担一切费用的范围里，免除上述承担费用不适用于你。

警告：在使用 nuoyoukao.com网络时违背了这些法规将构成对网站权益的侵犯或违反，并可导致对你采取法律行动。
`