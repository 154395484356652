import Vue from 'vue'
import { 
    Button,Icon,NavBar,Toast,Dialog,Loading,Divider,ActionSheet,Checkbox,Popup,DatetimePicker,field,treeSelect,calendar ,area,picker 
    ,Search,Cell} from 'vant';
    Vue.use(Button);
    Vue.use(Icon);
    Vue.use(NavBar);
    Vue.use(Toast);
    Vue.use(Dialog);
    Vue.use(Loading);
    Vue.use(Divider);
    Vue.use(ActionSheet);
    Vue.use(Checkbox);
    Vue.use(Popup);
    Vue.use(DatetimePicker);
    Vue.use(field);
    Vue.use(treeSelect);
    Vue.use(calendar);
    Vue.use(area);
    Vue.use(picker);
    Vue.use(Search);
    Vue.use(Cell);
