<template>
  <div id="app">
    <component :is="isPcOrMobile"></component>
  </div>
</template>

<script>
import pcComponent from './components/pc/index.vue'
import mobileComponent from './components/mobile/index.vue'
import { isMobile } from '@/lib/public.js'
import { createNvcScript } from "@/lib/nvcVerify.js";

export default {
  name: 'App',
  data(){
    return {
      isPcOrMobile:"pcComponent"
    }
  },
  components: {
    mobileComponent,
    pcComponent
  },
  created(){
    this.init();
  },
  methods:{
    init(){
      // 阿里人机验证begin
      createNvcScript();
      // 阿里人机验证end
      this.isPcOrMobile = isMobile() ? "mobileComponent" : "pcComponent";
    },
  },
}
</script>

<style>
  html,body{
    margin:0;
    font-size: 15px;
  }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
/* 解决人机验证 生产环境样式问题 */
.sm-btn-default .shield, .sm-btn-fail .shield, .sm-btn-loading .shield, .sm-btn-success .shield{
  top: 10px;
}
.sm-btn-default .shield svg, .sm-btn-fail .shield svg, .sm-btn-loading .shield svg, .sm-btn-success .shield svg{
  display: block;
}
/* 解决人机验证 生产环境样式问题 end */

</style>
