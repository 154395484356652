<template>
  <div>
    <span @click.stop="showPolicy = true"
      >登录注册代表同意 <span class="strong">《用户协议》</span>和<span
        class="strong"
        >《隐私政策》</span
      ></span
    >
    <van-popup
      v-model="showPolicy"
      round
      style="width: 80%"
      @close="showPolicy = false"
      closeable
    >
      <div class="agreement">
        <div class="content" v-html="policy"></div>
      </div>
    </van-popup>
    <!-- </van-checkbox> -->
  </div>
</template>
<script>
import { policy } from "@/lib/policy";
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(val) {
      this.checked = val;
    },
    checked(val) {
      this.$emit("input", val);
      localStorage.setItem("noahAgreement", val);
    },
  },
  data() {
    return {
      checked: this.value,
      showPolicy: false,
      content: {},
      policy,
    };
  },
};
</script>
<style scoped>
.agreement {
  height: 70vh;
  text-align: left;
  padding: 10px;
}
.agreement .content {
  white-space: pre-wrap;
  width: 100%;
  overflow: hidden;
  line-height: 1.5;
}
.agreement .content >>> img {
  width: 100%;
}
.agreement .title {
  color: #333;
  text-align: center;
  font-size: 16px;
  padding: 10px;
}
.strong {
  color: #266af3;
}
</style>