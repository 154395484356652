import { validTelephone } from '@/lib/validate.js';
export default{
    data(){
        return {
            show:false,
            number:0,
            interval:"",
            ic:""
        }
    },
    methods:{
        smartCaptcha(){
            // 人机验证
            let that = this;
            document.getElementById("sc").innerHTML = "";
            this.ic = new smartCaptcha({
                  //声明智能验证需要渲染的目标元素ID。
                  renderTo: '#sc',
                  //智能验证组件的宽度。
                  width: '100%',
                  //智能验证组件的高度。
                  height: 40,
                  //智能验证组件初始状态文案。
                  default_txt: '点击按钮开始智能验证',
                  //智能验证组件验证通过状态文案。
                  success_txt: '验证成功',
                  //智能验证组件验证失败（拦截）状态文案。
                  fail_txt: '验证失败，请在此点击按钮刷新',
                  //智能验证组件验证中状态文案。
                  scaning_txt: '智能检测中',
                  //前端智能验证通过时会触发该回调参数。您可以在该回调参数中将请求标识（token）、会话ID（sessionid）、签名串（sig）字段记录下来，随业务请求一同发送至您的服务端调用验签。
                  success(data) {
                        that.show = false
                        that.sendTeleCode({
                            SessionId:data.sessionId,
                            Sig:data.sig,
                            Token:window.NVC_Opt.token,
                            Scene:window.NVC_Opt.scene
                        });
                  },
                  fail() {
                    console.log('ic error');
                  }
            });
            this.ic.init();
                console.log(this.ic)
        },
        openImgCode(){
            this.$emit("clearValidate")
            if(this.tel && validTelephone(this.tel)){
                this.show = true;
                this.$nextTick().then(()=>{
                    this.smartCaptcha();
                });
            }else{
                this.$message('请输入正确的手机号！')
            }
            
        },
        beforeDestroy() {
            if(this.interval) {
                clearInterval(this.interval);
            }
        },
        beginCount(){
            // 开始倒计时
            this.number = 60;
            clearInterval(this.interval);
            this.interval = setInterval(() => {
                if(this.number <= 60 && this.number > 0){
                    this.number --;
                }else{
                    clearInterval(this.interval);
                    this.interval = "";
                    this.number = 0;
                }
            },1000);
        },
        // 发送验证码
        sendTeleCode(opts){
            this.beginCount();
            this.$api.SendVerifyShortMessage({
                TelePhone: this.tel, 
                SessionID: opts.SessionId, 
                SIG: opts.Sig, 
                Token: opts.Token
            }).then(res => {
                window.localStorage.MessageID = res.MessageID;
            }).catch(() => {
                clearInterval(this.interval);
                this.interval = "";
                this.number = 0;
            })
        }
    }
}