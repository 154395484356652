import axios from 'axios'
import { Message } from 'element-ui'

function responseSuccessHandle(response){
    let whiteList = [401];
    if(response.status === 200){
        return response.data;
    }else{
        if(response.data && response.data.ErrMsg){
            if(whiteList.indexOf(response.data.ErrCode) == -1){
                // 非白名单提示错误消息
                Message.error(response.data.ErrMsg);
            }
        }else{
            Message.error('服务器异常，请联系管理员');
        }
        return Promise.reject(response.data);
    }
}

const request =  axios.create({
    validateStatus: function () {
        return true;
    }
});

request.interceptors.response.use(responseSuccessHandle);

export { request }