<template>
	<div>
		<div class="code-pop" v-show="show">
			<div id="sc" style="margin-left: 0px;margin-top: 0px;margin-right: 0px;margin-bottom: 0px"></div>
		</div>
		<el-input v-show="!show" :maxlength="6" class="form-input" type="text" v-model="code" @input="$emit('input',code)" placeholder="请输入验证码">
			<template slot="append">
				<el-button type="text" class="gebtn" :disabled="number > 0 || tel==''" size="small" @click="openImgCode">{{number == 0 ? "获取验证码" :number + "s"}}</el-button>
			</template>
		</el-input>
	</div>
</template>
<script>
	import mixins from "@/mixins/codeBtn";
	export default {
		props:{
			tel:String,
			value:String
		},
		data(){
			return {
				code:''
			}
		},
		mixins:[mixins],
	}	
</script>
<style lang="less" scoped>
	.gebtn{
		white-space: nowrap;
		padding: 10px;
		color: #0385F8 !important;
	}
	.code-wrap{
		display: flex;
		border: 1px solid #ddd;
		.code-input{
			padding: 10px;
			border: 0px;
			line-height: 1.5;
			flex: 2;
		}
		.code{
			flex: 1;
			display: block;
			height: 100%;
		}
	}
	.tip{
		color: red;
		padding-top: 10px;
		text-align: center;
	}
	.code-pop{
		background-color: #fff;
		width: 100%;
	}
	.form-input /deep/ input{
		font-size: 18px;
		font-size: inherit;
	}
	// .code-pop /deep/ .sm-btn-default .shield{
	// 	top:10px
	// }
</style>
