<template>
  <div class="agreement-block">
    <p class="mess" @click="showPolicy = true" style="cursor: pointer">
      登录代表同意<span style="color: #0385f8">用户协议</span>和<span
        style="color: #0385f8"
        >隐私政策</span
      >
    </p>
    <el-dialog
      :visible.sync="showPolicy"
      show-close
      @close="showPolicy = false"
      fullscreen
    >
      <div class="agreement" style="margin: 0 auto">
        <div class="content" v-html="policy"></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { policy } from "@/lib/policy";
export default {
  data() {
    return {
      showPolicy: false,
      policy,
    };
  },
};
</script>
<style scoped>
.agreement {
  width: 80vw;
  height: 70vh;
  text-align: left;
  padding: 10px;
}
.agreement .content {
  white-space: pre-wrap;
  width: 100%;
  overflow: hidden;
  line-height: 1.5;
}
.agreement .content >>> img {
  width: 100%;
}
.agreement .title {
  color: #333;
  text-align: center;
  font-size: 16px;
  padding: 10px;
}
.mess {
  margin: 0;
  color: #999;
  font-size: 13px;
  text-align: center;
  margin-top: 15px;
}
</style>