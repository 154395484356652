import Vue from 'vue'
import App from './App.vue'
import "./lib/element-ui.js"
import "./lib/vant.js"
import api from "./lib/api.js"

Vue.config.productionTip = false
Vue.prototype.$api = api;

new Vue({
  render: h => h(App),
}).$mount('#app')
