export function isMobile(){
	let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    return flag;
}

export function isWeixin(){
	var ua = navigator.userAgent.toLowerCase();//获取判断用的对象
	return ua.match(/MicroMessenger/i) == "micromessenger";
}
//判断是否是小程序
export function isMiniWeixin(){
	var ua = navigator.userAgent.toLowerCase();
	return ua.match(/MiniProgram/i) == "miniprogram";
}