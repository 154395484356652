/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export const pattern = {
	telephone:/^1[3456789]\d{9}$/,        //手机号
	code:/^\d{4}|\d{6}$/,           //验证码
	password:/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,24}$/,                    //密码
	email:/^\w+@[a-z0-9]+\.[a-z]{2,4}$/    //邮箱
}

export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  const valid_map = ['admin', 'editor']
  return valid_map.indexOf(str.trim()) >= 0
}


export function validTelephone(str){
	return pattern.telephone.test(str)
}

export function validCode(str){
	return pattern.code.test(str)
}
export function validEmail(str){
	return pattern.email.test(str)
}
export function validPassword(str){
	return pattern.password.test(str)

}
