import { Interact } from "@/lib/global.js"
import hex_md5 from "@/lib/md5.js"
import wx from 'weixin-js-sdk'
import { isWeixin, isMobile, isMiniWeixin } from "@/lib/public"


export default {
    data() {
        return {
            pageType: "2",   //1密码登录 2验证码登录 3微信登录 4重置密码
            Form: {
                telephone: "",
                code: "",
                password: ""
            },
            loading: false,
            isBindLogin: Interact.BindLogin != -1,
        }
    },
    created() {
        console.log(wx);
        // 微信登录
        if (isMiniWeixin()) {
            //微信小程序
            this.miniWeChatLogin();
        } else if (isWeixin() && !this.isBindLogin) {
            this.oauth();
        }
    },
    watch: {
        pageType(newval) {
            if (newval) {
                this.Form = {
                    telephone: "",
                    code: "",
                    password: ""
                };
                if (this.$refs['loginForm']) {
                    this.$refs['loginForm'].resetFields();
                }
            }
        }
    },
    methods: {
        //微信公众号授权  当判断当前是在微信公众号中打开时，进行调用
        oauth() {
            //判断如果是退出过来的就不需要跳转
            if (!Interact.FromOutLogin) {
                location.href = "/Auth/WeChatLogin?ReturnUrl=" + encodeURIComponent(Interact.ReturnUrl);
            }
        },
        //微信小程序登陆
        miniWeChatLogin() {
            var url = window.Interact.ReturnUrl;
            url=encodeURIComponent(url);
            console.log(url);
            wx.miniProgram.reLaunch({ url: '../login/login?ReturnUrl=' + url });
        },
        //诺聘登录
        nuopinLogin() {
            location.href = "/Auth/NuoPinLogin?ReturnUrl=" + encodeURIComponent(Interact.ReturnUrl);
        },
        successHandle() {
            location.href = Interact.ReturnUrl;
        },
        getMessageID() {
            return window.localStorage.MessageID || "";
        },
        // 账号密码登录
        SponsorLogin() {
            this.validate().then(() => {
                this.loading = true;
                let d = this.Form;
                this.$api.SponsorLogin({
                    LoginName: d.telephone,
                    LoginPwd: hex_md5(d.password)
                }).then(res => {
                    this.successHandle();
                    this.loading = false;
                }).catch(err => {
                    if (err.ErrCode === 403) {
                        // 提示：设置密码，验证码登录
                        this.openChangeModeDialog();
                    }
                    this.loading = false;
                })
            })
        },
        // 切换登录方式弹窗
        openChangeModeDialog() {
            if (isMobile) {
                this.$dialog.confirm({
                    title: '登录失败',
                    message: '换一种登录方式试试吧',
                    confirmButtonText: '重置密码',
                    cancelButtonText: '验证码登录',
                }).then(() => {
                    this.pageType = "4";
                }).catch(() => {
                    this.pageType = "2";
                });
            } else {
                this.$confirm('换一种登录方式试试吧', '登录失败', {
                    confirmButtonText: '重置密码',
                    cancelButtonText: '验证码登录',
                }).then(() => {
                    this.pageType = "4";
                }).catch(() => {
                    this.pageType = "2";
                })
            }
        },
        // 验证码登录
        CodeLogin() {
            this.validate().then(() => {
                this.loading = true;
                let d = this.Form;
                this.$api.CodeLogin({
                    LoginName: d.telephone,
                    MessageID: this.getMessageID(),
                    Code: d.code
                }).then(res => {
                    this.successHandle();
                    this.loading = false;
                }).catch(err => {
                    this.loading = false;
                })
            })
        },
        // 重置密码
        resetPassword() {
            let d = this.Form;
            let postData = {
                LoginName: d.telephone,
                MessageID: this.getMessageID(),
                Code: d.code,
                LoginPwd: hex_md5(d.password).toUpperCase()
            };
            this.validate().then(() => {
                this.loading = true;
                this.$api.ResetPassWordLogin(postData).then(res => {
                    this.successHandle();
                    this.loading = false;
                }).catch(err => {
                    this.loading = false;
                })
            })
        }
    }
}