<template>
	<div class="main">
		<div class="nav">
			<el-row class="nav-content" type="flex" justify="space-between" align="middle">
				<el-row :span="12">
					<div class="head-logo"><img :src="DefaultLogoUrl" alt=""></div>
				</el-row>
			</el-row>
		</div>
		<div class="bg">
			<div class="body">
				<div class="form-block">
					<el-form class="form" :model="Form" :rules="rules" ref="loginForm">
						<div v-show="pageType !==6  && pageType !== 3" class="noahqr" @click="noahApplogin">
							<img src="../../assets/pc/images/wechat3.png" alt="">
						</div>
						<div class="login-tab" v-if="pageType==1 || pageType==2">
							<el-tabs v-model="pageType"  @tab-click="(tab) => {pageType == tab.name}">
							    <el-tab-pane label="验证码登录" name="2">
							    	<!-- 验证码登录 -->
									<div class="form-content" v-if="pageType==2">
										<p class="mess-place" v-if="isBindLogin">第一次使用微信登录需绑定手机号码</p>
										<p class="mess-place" v-else>首次登录将自动注册</p>
										<el-form-item class="form-item" prop="telephone">
											<el-input class="form-input" :maxlength="11" type="text" v-model="Form.telephone" placeholder="请输入手机号"></el-input>
										</el-form-item>
										<el-form-item class="form-item" prop="code">
											<code-btn @clearValidate="clearValidate" v-model="Form.code" :tel="Form.telephone"></code-btn>
										</el-form-item>
										<el-button class="button" type="primary" size="large" @click="CodeLogin()" :disabled="loading">{{ loading ? '登录中...' : '登录'}}</el-button>
										<page-agreement></page-agreement>
										
									</div>
							    </el-tab-pane>
							    <el-tab-pane label="密码登录" name="1">
							    	<!-- 密码登录 -->
									<div class="form-content" v-if="pageType==1">
										<p class="mess-place"></p>
										<el-form-item class="form-item" prop="telephone">
											<el-input class="form-input" type="text" :maxlength="11" v-model="Form.telephone" placeholder="请输入手机号"></el-input>
										</el-form-item>
										<el-form-item class="form-item" prop="password" :rules="{ required:true, message:'请输入登录密码' }">
											<el-input class="form-input" :type="eyeStatus ? 'text' : 'password'" v-model="Form.password" placeholder="请输入密码">
												<template slot="append">
													<div v-show="eyeStatus == 0" @click="eyeStatus = !eyeStatus" class="eye"></div>
													<div v-show="eyeStatus == 1" @click="eyeStatus = !eyeStatus" class="eye2"></div>
												</template>
											</el-input>
										</el-form-item>
										<el-button class="button" type="primary" size="large" @click="SponsorLogin()" :disabled="loading">{{ loading ? '登录中...' : '登录'}}</el-button>
										<page-agreement></page-agreement>
										<div class="form-foot">
											<div class="item" @click="pageType = 4">
												忘记密码？
											</div>
										</div>
									</div>
							    </el-tab-pane>
							</el-tabs>
							<div class="qr" @click="nuopinLogin()">
								<img src="../../assets/pc/images/wechat2.png" alt="">
								<div>诺聘账号登录</div>
							</div>
						</div>
						<!-- 忘记密码 -->
						<div v-if="pageType==4" class="form-content">
							<div class="form-title">修改密码</div>
							<p class="mess-place">密码修改后自动登录</p>
							<el-form-item class="form-item" prop="telephone">
								<el-input class="form-input" type="text" v-model="Form.telephone" placeholder="请输入手机号"></el-input>
							</el-form-item>
							<el-form-item class="form-item" prop="code">
								<code-btn v-model="Form.code" :tel="Form.telephone"></code-btn>
							</el-form-item>
							<el-form-item class="form-item" prop="password" :rules="[{ required:true, message:'请输入登录密码'},{ pattern:pattern.password, message:'请输入8-24位数字字母组合密码' }]">
								<el-input class="form-input" :type="eyeStatus ? 'text' : 'password'" v-model="Form.password" maxlength="24" placeholder="请输入8-24位数字字母组合密码">
									<template slot="append">
										<img v-show="eyeStatus == 0" @click="eyeStatus = !eyeStatus" class="eye" src="../../assets/pc/images/eye.png" alt="">
										<img v-show="eyeStatus == 1" @click="eyeStatus = !eyeStatus" class="eye" src="../../assets/pc/images/eye-open.png" alt="">
									</template>
								</el-input>
							</el-form-item>
							<el-button class="button" type="primary" size="large" @click="resetPassword" :disabled="loading">{{ loading ? '登录中...' : '确定'}}</el-button>
							<page-agreement></page-agreement>
							<div class="form-foot">
								<div class="item" @click="pageType = '1'">
									密码登录
								</div>
								<div class="item"  @click="pageType = '2'">
									验证码登录
								</div>
							</div>
						</div>
						<!-- 微信扫码登录 -->
						<div v-if="pageType==6" class="form-content">
							<wxlogin :appid="wxloginData.appid"  :scope="wxloginData.scope" :redirect_uri="wxloginData.redirect_uri" :state="wxloginData.state"> </wxlogin>
							<!-- <div style="font-size:13px">打开微信“扫一扫”上方二维码</div> -->
							<page-agreement></page-agreement>
							<div class="form-foot">
								<div class="item"  @click="pageType = '2'">
									验证码登录
								</div>
								<div class="item" @click="pageType = '1'">
									密码登录
								</div>
							</div>
						</div>
					</el-form>
				</div>
			</div>
		</div>
		<div class="foot">
			<div class="foot-main">
				<el-row class="foot-block">
					<el-col :span="16">
						<el-row>
							<!-- <el-col :span="6">
								<div class="about-title">友情链接</div>
								<a href="#" class="about-a">诺聘网</a>
								<a href="#" class="about-a">诺亚人力资源外包</a>
							</el-col>
							<el-col :span="6">
								<div class="about-title">招聘外包</div>
								<a href="#" class="about-a">派遣招聘</a>
								<a href="#" class="about-a">招聘流程外包</a>
							</el-col> -->
							<el-col :span="6">
								<div class="about-title">关于我们</div>
								<a href="#" class="about-a">业务合作</a>
								<a href="#" class="about-a">联系我们</a>
							</el-col>
							<el-col :span="6">
								<div class="about-title">服务热线</div>
								<a href="#" class="about-a">18910774080</a>
							</el-col>
						</el-row>
					</el-col>
					<!-- <el-col :span="8">
						<el-row>
							<el-col :span="12">
								<div class="qr-code qr-code2"></div>
								<div class="qr-name">扫码找工作</div>
							</el-col>
							<el-col :span="12">
								<div class="qr-code"></div>
								<div class="qr-name">下载诺聘APP</div>
							</el-col>
						</el-row>
					</el-col> -->
				</el-row>
				<div class="copy-right">
					<p>
						<span>河北诺聘网络科技有限公司</span>
						<!-- <span>河北诺亚人力资源开发有限公司</span> -->
						<span>冀ICP备10006226号-9</span>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
  	import codeBtn from './codeBtn.vue'
  	import pageAgreement from './agreement.vue'
  	import { pattern } from '@/lib/validate.js'
	import mixins from "@/mixins/index.js"
	import { Interact,DefaultLogoUrl } from "@/lib/global.js"
	import wxlogin from 'vue-wxlogin';
	export default {
	data(){
		return {
			time:null,
			pageType:"2",   //1密码登录 2验证码登录  4重置密码 6微信扫码登录
			Form:{},
			eyeStatus:0,
			pattern:pattern,
			wxloginData:{},
			rules:{
				code:[{
					required:true,
					message:"请输入验证码",
				},{
					pattern:pattern.code,
					message:"请输入6位数字验证码"
				}],
				telephone:[{
					required:true,
					message:"请输入手机号",
				},{
					pattern:pattern.telephone,
					message:"手机号码格式不正确"
				}]
			},
			DefaultLogoUrl
		}
	},
	components:{
		codeBtn,
		pageAgreement,
		wxlogin
	},
	mixins:[mixins],
    methods:{
		// 表单校验
		validate(){
			return  new Promise((res,rej) => {
				this.$refs['loginForm'].validate(valid => {
					if(valid){
						res();
					}else{
						rej();
					}
				})
			})
			
		},
        // 微信扫一扫登录
        noahApplogin(){
            this.$api.WeChatScanCodeLogin({ReturnUrl:Interact.ReturnUrl}).then(ret => {
                //如果直接返回则表示已经扫码过了，直接进行跳转
                window.location.href = ret.ReturnUrl;
            }).catch(ret => {
				if (ret.ErrCode == 401) {
					//没有登录过
					let { ErrData } = ret;
					this.wxloginData = ErrData;
					this.wxloginData.redirect_uri = encodeURIComponent(ErrData.redirect_uri)
					this.pageType = 6;
                }
            })
        },
		clearValidate(){
			this.$refs.loginForm.clearValidate()
		}
    }
	}
</script>
<style lang="less" scoped>
	@import '~@/assets/pc/styles/index.less';
</style>